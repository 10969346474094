import { Button } from "@edgetier/components";
import { doNothing } from "@edgetier/utilities";
import { faEllipsisH, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Arrow, useHover, useLayer } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";

import EllipsisMenuProvider from "./ellipsis-menu-provider/ellipsis-menu-provider";
import "./ellipsis-menu.scss";
import { IProps } from "./ellipsis-menu.types";

/**
 * A floating menu triggered by clicking an ellipsis icon.
 * NOTE: isLockedOpen may not work as expected when trigger is set to hover.
 * @param props.children           The contents of the menu.
 * @param props.trigger            The trigger for the menu. Can be "click" or "hover".
 * @param props.isButton           When true, the menu will be triggered by a button instead of an icon.
 * @param props.isLockedOpen       When true, the menu will not close if the user clicks outside of it.
 * @param props.isVerticalEllipsis When true, the menu will use a vertical ellipsis icon.
 * @param props.menuLabel          An optional label for the menu.
 * @param props.overflowContainer  Boolean indicating if the menu should break out of the container.
 * @param props.placement          An optional placement for suggesting where to position the menu.
 * @param props.useArrow           Use an arrow that points at the menu trigger.
 */
const EllipsisMenu: FunctionComponent<IProps> = ({
    children,
    className,
    trigger = "click",
    isButton = false,
    isLockedOpen: initialIsLockedOpen = false,
    isVerticalEllipsis = false,
    menuLabel,
    overflowContainer = true,
    placement,
    useArrow = true,
    onTriggerClick,
}) => {
    const [isOpenByClick, setIsOpenByClick] = useState(false);
    const [isLockedOpen, setIsLockedOpen] = useState(initialIsLockedOpen);
    const [isHovered, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 });

    const close = useCallback(() => setIsOpenByClick(false), []);

    const isOpen = useMemo(() => {
        return (trigger === "hover" ? isHovered : isOpenByClick) || isLockedOpen;
    }, [trigger, isOpenByClick, isHovered, isLockedOpen]);

    // If the menu is unlocked, close it.
    useEffect(() => {
        if (!isLockedOpen) {
            close();
        }
    }, [isLockedOpen, close]);

    const triggerClose = useCallback(() => {
        if (typeof onTriggerClick === "function") {
            onTriggerClick();
        }
        close();
    }, [onTriggerClick, close]);

    const { layerProps, arrowProps, renderLayer, triggerProps } = useLayer({
        auto: true,
        isOpen,
        onDisappear: (disappearType) => {
            if (isLockedOpen || disappearType !== "full") {
                doNothing();
            } else {
                triggerClose();
            }
        },
        onOutsideClick: isLockedOpen ? doNothing : triggerClose,
        onParentClose: triggerClose,
        overflowContainer,
        placement,
        ResizeObserver,
        triggerOffset: useArrow ? 12 : 5,
    });

    const menu = useMemo(
        () => (
            <div
                className={classnames("ellipsis-menu", { "ellipsis-menu--closed": !isOpen }, className)}
                {...layerProps}
            >
                <EllipsisMenuProvider
                    context={{
                        close: triggerClose,
                        isOpen,
                        isLockedOpen,
                        lockOpen: () => setIsLockedOpen(true),
                        unlock: () => setIsLockedOpen(false),
                    }}
                >
                    {useArrow && (
                        <div className="ellipsis-menu__arrow" data-testid="ellipsis-menu-arrow">
                            <Arrow {...(arrowProps as any)} />
                        </div>
                    )}
                    <ul {...hoverProps}>{children}</ul>
                </EllipsisMenuProvider>
            </div>
        ),
        [isOpen, children, className, layerProps, arrowProps, hoverProps, useArrow, triggerClose, isLockedOpen]
    );

    const icon = isVerticalEllipsis ? faEllipsisV : faEllipsisH;
    const onClick = () => {
        if (typeof onTriggerClick === "function") {
            onTriggerClick();
        }

        isOpenByClick ? close() : setIsOpenByClick(true);
    };

    return (
        <>
            {isButton && (
                <div {...triggerProps} {...hoverProps}>
                    <Button aria-label="ellipsis-menu" icon={icon} onClick={onClick}>
                        {menuLabel}
                    </Button>
                </div>
            )}

            {!isButton && (
                <div
                    aria-label="ellipsis-menu"
                    className="ellipsis-menu__trigger"
                    onClick={onClick}
                    role="button"
                    {...triggerProps}
                    {...hoverProps}
                >
                    {typeof menuLabel !== "undefined" && <span className="ellipsis-menu__label">{menuLabel}</span>}
                    <FontAwesomeIcon icon={icon} />
                </div>
            )}

            {isOpen && renderLayer(menu)}
        </>
    );
};

export default EllipsisMenu;
